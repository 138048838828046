import ProjectRightHeader from "../ProjectRightHeader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CollapseWithGrid from "../../../ui/customCollapse/CollapseWithGrid";
import { useEffect, useState } from "react";
import EmptyList from "../../../ui/emptyList/EmptyList";
import CustomButton from "../../../ui/button/CustomButton";
import { useNavigate } from "react-router";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "../../../ui/loader/Loader";
import { api } from "../../../../redux/api/api";
import { useSelector } from "react-redux";
import moment from "moment";
import { config } from "../../../../config";

const useStyles = makeStyles((theme) => ({
  artImg: {
    width: "100%",
    // height: "200px",
    cursor: "pointer",
  },
  res: {
    fontSize: "18px",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "10px",
  },
  nameDate: {
    fontSize: "16px",
    marginTop: "4px",
    paddingRight: "4px",
  },
}));

const FeedbackSection = (props) => {
  const loc = useNavigate();
  const classes = useStyles();
  const projectId = useSelector((state) => state.project.project.uuid);
  const [expand, setExpand] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [artWorks, setArtWorks] = useState([]);

  useEffect(() => {
    setPageLoading(true);
    if (projectId) {
      getArtworks();
    }
  }, []);

  const getArtworks = () => {
    api(`artwork/list/${projectId}`, {}, "get", "", "")
      .then((res) => {
        if (res.success) {
          setArtWorks(res.artworks);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setPageLoading(false);
      });
  };

  return (
    <>
      <ProjectRightHeader
        title="Feedback Pro"
        mt="2rem"
        setCollsapn={(a) => setExpand(a)}
        isHideExpend={true}
        btn={
          <CustomButton
            name="Add"
            size="small"
            endIcon={<AddCircleOutlineRoundedIcon />}
            onclick={() => loc("/projects/artwork-people")}
          />
        }
      />
      <CollapseWithGrid expand={expand}>
        {pageLoading ? (
          <Loader />
        ) : artWorks.length > 0 ? (
          artWorks.map((el) => (
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              pr={2}
              pb={3}
              margin="auto 0"
            >
              <img
                alt="Artwork Img"
                src={`${config.dwdPath}${el.previewImage}`}
                className={classes.artImg}
                onClick={() => loc(`/projects/review-feedback?aid=${el.uuid}`)}
              />
              <div
                className={classes.res}
                onClick={() => loc(`/projects/review-feedback?aid=${el.uuid}`)}
              >
                {el?.name || "-"}
              </div>
              <div className={classes.nameDate}>
                {`${
                  el?.status === "APPROVED" ? "Approved " : "Last feedback: "
                }${moment(el.dateModified).fromNow(true)} ago` || "-"}
              </div>
            </Grid>
          ))
        ) : (
          <EmptyList color="#F19C7E" text="No Feedback Found." />
        )}
      </CollapseWithGrid>
    </>
  );
};

export default FeedbackSection;
