import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
      position: "bottom",
    },
  },
};

const AuGraph = ({ data = [], flex = false }) => {
  const [isAbs, setAbs] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [shareData, setShareData] = useState("");
  const [populationData, setPopulationData] = useState("");
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const handleData = () => {
    const d = [];
    let wantToCheck = true;
    for (let i = 0; i < data.length; i++) {
      if (data[i].attributeName && data[i].attributeName.includes("ABS -"))
        break;
      if (data[i].attributeName) {
        const temp = {
          name: data[i].attributeName,
          shareOfItem: data[i].shareOfItem?.toFixed(2) || 0,
          attributePopulation: data[i].attributePopulation,
        };
        if (wantToCheck) {
          const f = data.findIndex(
            (a) => a.attributeName === `ABS - ${data[i].attributeName}`
          );
          if (f !== -1 && data[f].shareOfItem !== null) {
            temp.shareOfItemAbs = data[f].shareOfItem?.toFixed(2) || 0;
            temp.attributePopulationAbs = data[f].attributePopulation;
          } else {
            wantToCheck = false;
          }
        }
        d.push(temp);
      }
    }
    const label = d.map((a) => a.name || "");
    const sd = {
      labels: label,
      datasets: [
        {
          label: "Share of item",
          data: d.map((a) => a.shareOfItem || 0),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
    const pd = {
      labels: label,
      datasets: [
        {
          label: "Population",
          data: d.map((a) => a.attributePopulation || 0),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
    if (wantToCheck) {
      sd.datasets.push({
        label: "ABS share of item",
        data: d.map((a) => a.shareOfItemAbs || 0),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      });
      pd.datasets.push({
        label: "ABS population",
        data: d.map((a) => a.attributePopulationAbs || 0),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      });
    }
    setShareData(() => sd);
    setPopulationData(() => pd);

    setTableData(() => d);
    setAbs(() => wantToCheck);
  };

  useEffect(() => {
    handleData();
  }, [data]);
  return (
    <Grid item lg={12} md={12} xs={12} sm={12}>
      <Stack gap="20px">
        <TableContainer>
          <Table sx={flex ? {} : { minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Group</TableCell>
                <TableCell align="right">Population</TableCell>
                <TableCell align="right">Share of item</TableCell>
                {isAbs && <TableCell align="right">ABS Population</TableCell>}
                {isAbs && (
                  <TableCell align="right">ABS Share of item</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.attributePopulation}</TableCell>
                  <TableCell align="right">{row.shareOfItem} %</TableCell>
                  {isAbs && (
                    <TableCell align="right">
                      {row.attributePopulationAbs}
                    </TableCell>
                  )}
                  {isAbs && (
                    <TableCell align="right">{row.shareOfItemAbs} %</TableCell>
                  )}{" "}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {matches && flex ? (
          <Stack gap="20px">
            {shareData && (
              <Box m={2} maxHeight={matches ? "160px" : "360px"}>
                <Bar
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        ...options.plugins.title,
                        text: "Share of item",
                      },
                    },
                  }}
                  data={shareData}
                />
              </Box>
            )}
            {populationData && (
              <Box m={2} maxHeight={matches ? "160px" : "360px"}>
                <Bar
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: { ...options.plugins.title, text: "Population" },
                    },
                  }}
                  data={populationData}
                />
              </Box>
            )}
          </Stack>
        ) : (
          <Stack direction={"row"} justifyContent="space-between">
            {shareData && (
              <Box m={2} width="100%" maxWidth="660px">
                <Bar
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: {
                        ...options.plugins.title,
                        text: "Share of item",
                      },
                    },
                  }}
                  data={shareData}
                />
              </Box>
            )}
            {populationData && (
              <Box m={2} width="100%">
                <Bar
                  options={{
                    ...options,
                    plugins: {
                      ...options.plugins,
                      title: { ...options.plugins.title, text: "Population" },
                    },
                  }}
                  data={populationData}
                />
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default AuGraph;
