export const ToolType = {
  BRAND_STRATEGY: "Brand Strategy",
  CREATIVE_BRIEF: "Creative Brief",
  MEDIA_BRIEF: "Media Brief",
  CREATIVE_COMPETITIVE_OVERVIEW: "Creative Competitive Overview",
  COMPETITIVE_MAPPING: "Competitive Mapping",
  SCRIPT_TEMPLATE: "Script Template",
  CUSTOM: "Custom Tool",
  PUBLIC_RELATIONS: "Public Relations (PR)",
  ART_APPROVAL: "Art Approval",
};
export const ToolTypeRev = {
  "Brand Strategy": "BRAND_STRATEGY",
  "Creative Brief": "CREATIVE_BRIEF",
  "Media Brief": "MEDIA_BRIEF",
  "Creative Competitive Overview": "CREATIVE_COMPETITIVE_OVERVIEW",
  "Competitive Mapping": "COMPETITIVE_MAPPING",
  "Script Template": "SCRIPT_TEMPLATE",
  "Custom Tool": "CUSTOM_TOOL",
  "Public Relations (PR)": "PUBLIC_RELATIONS",
  "Art Approval": "ART_APPROVAL",
};
export const ToolOptions = [
  "Open Tool",
  "Rename Tool",
  "Remove from Project",
  "Copy to Another Project",
];

export const ToolStatusOptions = [
  "New",
  "Planning",
  "Underway",
  "Workshop Completed",
  "Revisions Underway",
  "Final Feedback",
  "Completed",
];

export const StatusOptions = [
  "New",
  "With Client",
  "In Production",
  "Workshop",
  "On Hold",
  "Completed",
  "Archived",
];

export const ResearchOption = [
  "Open Research",
  "Rename Research",
  //  "Update Research",
  "Remove from Project",
  "Copy to another Project",
];

export const DwdOptions = [
  "Download",
  //"Download Notes",
  "Remove Download",
  // "Generate New Download",
];

export const agencyType = [
  "Advertising Agency",
  "Activation Agency",
  "Brand Strategist",
  "Branding Agency",
  "Consultant",
  "Data & Research",
  "Design Studio",
  "Digital Agency",
  "Direct Marketing",
  "Integrated Creative Agency",
  "Marketing Agency",
  "Marketing Company",
  "Media Agency",
  "Public Relations Agency",
  "Social Media Agency",
];

export const adminOps = [
  // { uuid: "USER", name: "User" },
  // { uuid: "SYSTEM", name: "System" },
  { uuid: "AGENCIES_MANAGE", name: "Manage Agencies" },
  { uuid: "USERS_MANAGE", name: "Manage Users" },
  { uuid: "PROJECTS_MANAGE", name: "Manage Projects" },
  { uuid: "REPORTING_VIEW", name: "View Reporting" },
];
export const userManageOps = ["Edit", "Active", "Inactive"];

export const researchMenuOption = {
  ROY_MORGAN: "Roy Morgan",
  FONTO: "Fonto",
  AUSTRALIA_POST: "Australia Post",
  CUSTOM: "DIY",
};

export const researchState = {
  "australia-post": [
    { uuid: "ALL", name: "All States" },
    { uuid: "ACT", name: "ACT" },
    { uuid: "NSW", name: "New South Wales" },
    { uuid: "NT", name: "Northern Territory" },
    { uuid: "QLD", name: "Queensland" },
    { uuid: "SA", name: "South Australia" },
    { uuid: "TAS", name: "Tasmania" },
    { uuid: "VIC", name: "Victoria" },
    { uuid: "WA", name: "Western Australia" },
  ],
  "roy-morgan": [
    { uuid: "NSW", name: "New South Wales" },
    { uuid: "QLD", name: "Queensland" },
    { uuid: "VIC", name: "Victoria" },
    { uuid: "WA", name: "Western Australia" },
  ],
};
export const userPermission = ["None", "Lead", "Access"];
export const RegionResEnum = {
  AUSTRALIA: "Australia",
  AUSTRALIA_URBAN: "Urban",
  AUSTRALIA_RURAL: "Rural",
};

export const AUSTRALIA_POST_DATA_POINT = [
  {
    uuid: "AGE - summary",
    name: "Age",
    include: false,
  },
  { uuid: "SOCIO-ECONOMIC SCALE", name: "Socio-economic", include: false },
  { uuid: "STATES", name: "Geography - by state", include: false },
  {
    uuid: "PURCHASE - MONTH OF YEAR",
    name: "Purchase - Month of year",
    include: false,
  },
  {
    uuid: "PURCHASE - TIME OF DAY",
    name: "Purchase - Time of day",
    include: false,
  },
  { uuid: "PURCHASE - DEVICE", name: "Purchase - Device", include: false },
];

export const openingScreens = [
  {
    name: "welcome",
    img: "static/openingScreens/workshop.png",
    workshop: false,
    include: true,
  },
  {
    name: "competitors",
    img: "static/openingScreens/competitor.png",
    workshop: false,
    include: false,
  },
  {
    name: "meeting-customer-needs",
    img: "static/openingScreens/meeting-customer-needs.png",
    workshop: false,
    include: true,
  },
  {
    name: "brand-archetypes",
    img: "static/openingScreens/brand-archetypes.png",
    workshop: true,
    include: true,
    workshopImg: [
      "static/openingScreens/arch-img1.png",
      "static/openingScreens/arch-img2.png",
      "static/openingScreens/arch-img3.png",
      "static/openingScreens/arch-img4.png",
    ],
  },
  {
    name: "strengths-and-weaknesses",
    img: "static/openingScreens/strength-wekness.png",
    workshop: false,
    include: true,
  },
  {
    name: "brand-personality",
    img: "static/openingScreens/brand-personality.png",
    workshop: true,
    include: true,
    workshopImg: [
      "static/openingScreens/brand-personality-img1.png",
      "static/openingScreens/brand-personality-img2.png",
      "static/openingScreens/brand-personality-img3.png",
      "static/openingScreens/brand-personality-img4.png",
      "static/openingScreens/brand-personality-img5.png",
      "static/openingScreens/brand-personality-img6.png",
    ],
  },
  {
    name: "brand-values",
    img: "static/openingScreens/brand-value.png",
    workshop: true,
    include: true,
    workshopImg: [
      "static/openingScreens/brand-value-img1.png",
      "static/openingScreens/brand-value-img2.png",
      "static/openingScreens/brand-value-img3.png",
      "static/openingScreens/brand-value-img4.png",
      "static/openingScreens/brand-value-img5.png",
      "static/openingScreens/brand-value-img6.png",
    ],
  },
  {
    name: "brand-essence",
    img: "static/openingScreens/brand-essence.png",
    workshop: true,
    include: true,
    workshopImg: [
      "static/openingScreens/brand-essence-img1.png",
      "static/openingScreens/brand-essence-img2.png",
      "static/openingScreens/brand-essence-img3.png",
      "static/openingScreens/brand-essence-img4.png",
      "static/openingScreens/brand-essence-img5.png",
      "static/openingScreens/brand-essence-img6.png",
      "static/openingScreens/brand-essence-img7.png",
    ],
  },
  {
    name: "brand-proof-points",
    img: "static/openingScreens/brand-proof-points.png",
    workshop: true,
    include: true,
    workshopImg: [
      "static/openingScreens/brand-proof-points-img1.png",
      "static/openingScreens/brand-proof-points-img2.png",
      "static/openingScreens/brand-proof-points-img3.png",
      "static/openingScreens/brand-proof-points-img4.png",
      "static/openingScreens/brand-proof-points-img5.png",
    ],
  },
  {
    name: "elevator-pitch",
    img: "static/openingScreens/elevator-pitch.png",
    workshop: true,
    include: true,
    workshopImg: [
      "static/openingScreens/elevator-pitch-img1.png",
      "static/openingScreens/elevator-pitch-img2.png",
      "static/openingScreens/elevator-pitch-img3.png",
      "static/openingScreens/elevator-pitch-img4.png",
    ],
  },
  {
    name: "competitor-mapping",
    img: "static/openingScreens/Competitor-mapping.png",
    workshop: false,
    include: true,
  },
  {
    name: "market",
    img: "static/openingScreens/market.png",
    workshop: false,
    include: true,
  },
];

export const plans = [
  // {
  //   id: "BASIC",
  //   name: "basic",
  //   desc: (
  //     <div>
  //       Try out our toolkit for
  //       <br />
  //       free. Upgrade to access the
  //       <br />
  //       full Brandvas experience.
  //     </div>
  //   ),
  //   price: "0",
  //   price2: "0",
  //   features: [
  //     "Single User",
  //     "✔ Creative brief",
  //     "✔ Media brief",
  //     <div>
  //       Access to market <br />
  //       research and personas
  //       <br /> <span className="was">$499</span> $199 Launch rate*
  //     </div>,
  //   ],
  //   free: true,
  // },
  {
    id: "INDIVIDUAL",
    name: "Individual",
    // desc: (
    //   <div>
    //     For solo operators who
    //     <br />
    //     want to match the swag
    //     <br />
    //     of bigger agencies.{" "}
    //   </div>
    // ),
    price: "39",
    price2: "49",
    features: [
      "Single User",
      "✔ Unlimited access to all tools",
      "✔ Unlimited access to Market Research",
      "✔ Unlimited access to Feedback Pro",
    ],
    btnText: "",
    btnUrl: "",
  },
  {
    id: "STANDARD",
    name: <div>Standard</div>,
    // desc: (
    //   <div>
    //     Upgrade your skillset and
    //     <br />
    //     supercharge your team to
    //     <br />
    //     do more and do it better.
    //   </div>
    // ),
    price: "99",
    price2: "119",
    features: [
      "1 - 5 Users",
      "✔ Unlimited access to all tools",
      "✔ Unlimited access to Market Research",
      "✔ Unlimited access to Feedback Pro",
      "✔ Full access to User Management",
    ],
    btnText: "",
    btnUrl: "",
  },
  {
    id: "PRO",
    name: "Pro",
    // desc: (
    //   <div>
    //     Increase staff capability
    //     <br />
    //     and streamline agency
    //     <br />
    //     productivity.
    //   </div>
    // ),
    price: "199",
    price2: "229",
    features: [
      "6 - 20 Users",
      "✔ Unlimited access to all tools",
      "✔ Unlimited access to Market Research",
      "✔ Unlimited access to Feedback Pro",
      "✔ Full access to User Management",
    ],
    btnText: "",
    btnUrl: "",
  },
  {
    id: "ENTERPRICE",
    name: "Enterprise",
    // desc: (
    //   <div>
    //     For agencies who want
    //     <br />
    //     to do more and do
    //     <br />
    //     it your way.
    //   </div>
    // ),
    features: ["✔ Tailored onboarding", "✔ Premium support"],
    connect: true,
    btnText: "Contact us",
    btnUrl: "https://www.brandvas.io/contact-us",
  },
];

export const b2bCategories = [
  { uuid: "c992aae2-4a81-421b-9cc4-f7358a408f18", name: "Agribusiness" },
  {
    uuid: "81f3da1d-d3e1-42e7-a7c7-6aef6ba88dd1",
    name: "Architectural Services",
  },
  { uuid: "9d6e5bff-b475-42ba-b46b-dd252a8620a8", name: "Attorneys/Law Firms" },
  {
    uuid: "8bbd85d4-e3ec-431e-ad99-875fc075da9c",
    name: "Business Decisions Made",
    royMorgan: true,
  },
  {
    uuid: "0321dabb-0066-4def-99cc-e4baa67c9750",
    name: "Call centre industry",
  },
  {
    uuid: "01b0539f-72ca-40ae-89f9-0042962750d6",
    name: "Employment Industry",
    royMorgan: true,
  },
  {
    uuid: "9b730b1b-10bd-4866-ab9d-281af639694d",
    name: "Finance / Credit Companies Finance",
  },
  {
    uuid: "abce5ba5-e47e-45eb-8c8f-d0c462b5d987",
    name: "Food Products Manufacturing",
  },
  { uuid: "db0283d6-6dce-49cb-91ce-834c3d80cc91", name: "Food Stores" },
  { uuid: "6677e998-39b7-40d8-820a-c04d067b5229", name: "Hedge Funds" },
  { uuid: "89645f8b-5ba6-4670-8ef5-9ddef5ee14e3", name: "Hospitals" },
  { uuid: "5fa5c112-eea9-4992-9522-551ffe2f59ad", name: "Journalism" },
  {
    uuid: "0bef2066-c8af-4001-8162-4a921ae89af1",
    name: "Main Earner Occupation ANZSCO - Major Groups",
    royMorgan: true,
  },
  { uuid: "f8835a94-2f3e-4108-b159-1c23a3611a6a", name: "Medical Supplies" },
  { uuid: "9f6c3c6a-2c16-4f98-98e7-7e22e19b71cf", name: "Mining" },
  { uuid: "19f6090f-69bf-47d6-b287-f92ac00d90ce", name: "Modeling" },
  { uuid: "de2c0ac5-acf9-4df1-a6af-2ee3139b49f4", name: "Mortgage Bankers" },
  { uuid: "dbdf27f8-73a4-45eb-8356-ea2c211ba6d3", name: "Music Production" },
  {
    uuid: "f69934ff-5bc7-40a1-afb7-4f90db5421a1",
    name: "Newspaper, Magazine & Book Publishing",
  },
  {
    uuid: "108e5735-7245-42d4-8272-ad1076258561",
    name: "Occupation",
    royMorgan: true,
  },
  {
    uuid: "94740efc-0c70-4818-a725-07d9a283ecc3",
    name: "Private Equity & Investment Firms",
  },
  {
    uuid: "5ec5350e-b145-4234-9f5f-82460756e079",
    name: "Small Business Owners",
    royMorgan: true,
  },
  { uuid: "01fc6dec-e23e-47f1-9c49-669b6334ef7a", name: "Trucking" },
  { uuid: "0d97ded3-751b-4ae1-a28a-110a50d71ed3", name: "TV / Movies / Music" },
  { uuid: "15040a96-39c9-4544-961e-6cccc74cf6b8", name: "Waste Management" },
  {
    uuid: "ff86ec2a-6c7a-4a54-bafd-85623cff0dc3",
    name: "Water, sewerage and drainage services",
  },
  { uuid: "29199f87-010a-4d84-b3ae-9679bdb56806", name: "Other" },
];

export const b2cCategories = [
  { uuid: "2c803557-e88c-400a-ab6c-77c2e8ce9de9", name: "Accountants" },
  {
    uuid: "9d8c1edd-4427-42c8-9182-2a70e2f81035",
    name: "Activities and Interests",
    royMorgan: true,
  },
  {
    uuid: "0481e4ca-5026-4dd4-8780-4c0b3aa304a0",
    name: "Air Travel",
    royMorgan: true,
  },
  {
    uuid: "7cf0fed7-31ec-4e92-a32d-8bc495d34dcf",
    name: "Amusement, events and recreation industry",
  },
  {
    uuid: "05abbebe-b5ff-4a05-914a-49be1bb4c03b",
    name: "Animal care and veterinary services",
  },
  {
    uuid: "b193cff2-89ff-4a97-b022-ef149da6f9b2",
    name: "Appliances/items",
    royMorgan: true,
  },
  {
    uuid: "8d9750ee-f1b3-44c2-916c-296e63648f28",
    name: "Architectural Services",
  },
  {
    uuid: "09f479ff-6ef2-4975-8269-313a0186d96a",
    name: "Attitudes and Lifestyles",
    royMorgan: true,
  },
  {
    uuid: "7427d071-d34e-479d-80d9-c4016406de99",
    name: "Automotive",
    royMorgan: true,
  },
  { uuid: "bd2c13a5-a174-41ac-b5c0-c07c965689b1", name: "Beauty" },
  {
    uuid: "b0bf04e8-c187-4c52-9aea-ebb29944da27",
    name: "Builders	- Commercial/Residential",
  },
  { uuid: "da2f8b14-3908-48ae-bdb9-bad4e78c6fd7", name: "Café" },
  {
    uuid: "71ac9197-52c3-4c2a-b7bd-02e535c54eb3",
    name: "Cards",
    royMorgan: true,
  },
  {
    uuid: "03b3384f-71e1-4268-9e65-ecd9c17f8465",
    name: "Colleges, Universities & Schools",
  },
  {
    uuid: "f9ab0522-e47e-413c-bab7-246bf1c136fb",
    name: "Computer Software - Apps, Programs",
  },
  { uuid: "e33f5e1d-b84a-459e-82ca-1809a6779d99", name: "Cruise Lines" },
  { uuid: "59b7a841-2354-418a-afac-620ab10c3e6b", name: "Defense" },
  {
    uuid: "69e66999-84b2-4446-b383-09360e13a5e9",
    name: "Demographics/Profiling",
    royMorgan: true,
  },
  {
    uuid: "59d3ddd8-b494-4fef-8f27-013b5809ccc3",
    name: "Dry cleaning and laundry services",
  },
  {
    uuid: "13b55d6f-adfe-4cdb-a061-b291cb3bbc47",
    name: "Fashion",
    ausPost: true,
    categories: [
      {
        uuid: "4bbbeafb-ee0d-40dc-8e5b-b6cc4d67516e",
        name: "Athleisure",
        ausPost: true,
      },
      {
        uuid: "5f284434-9f67-4c7e-842f-eb44fcd0d3a7",
        name: "Accessories",
        ausPost: true,
      },
      {
        uuid: "aaefb261-7d83-46bd-a680-cd1b7b7c3112",
        name: "Footwear",
        ausPost: true,
      },
      {
        uuid: "d039c0b9-a0a7-4003-9f3d-a660d351b060",
        name: "Women's Fashion",
        ausPost: true,
      },
    ],
  },
  {
    uuid: "56ff2580-6a20-4dee-bc96-5346fa8bb874",
    name: "Financial Services",
    royMorgan: true,
  },
  { uuid: "8be9065a-c357-42e1-baaf-4471d6843474", name: "Fire Services" },
  {
    uuid: "22e25e8b-ff6b-40c1-8d98-fea613b35be8",
    name: "Food & Beverages",
    royMorgan: true,
  },
  {
    uuid: "505dd578-f811-41c9-a514-afe22944606c",
    name: "Food & Liquor - Wine & Liquor",
    ausPost: true,
  },
  {
    uuid: "9a86826e-fb06-4e2d-87a3-226c39d28e26",
    name: "Food Processing - Food Product Manufacturing",
  },
  { uuid: "7e664717-9d7f-4d05-81c5-8766728e9037", name: "Funeral Services" },
  { uuid: "feb726b9-0471-4045-be36-fe8273eab354", name: "Gambling & Casinos" },
  { uuid: "fe79f0ef-a60c-4680-8059-82d18f3dd09c", name: "Gardening" },
  { uuid: "1d2a9781-6bf4-40e2-82a5-3eca67a238b2", name: "Health" },
  {
    uuid: "8444dadc-744d-4b13-a2ce-ecc2a499394b",
    name: "Health & Beauty",
    ausPost: true,
    categories: [
      {
        uuid: "15b31b4e-8c8a-4228-8040-821ae68c70f4",
        name: "Beauty & Cosmetics",
        ausPost: true,
      },
      {
        uuid: "3b4af59c-c352-4396-b809-30014c1216ea",
        name: "Health & Pharmaceutical",
        ausPost: true,
      },
    ],
  },
  {
    uuid: "fa63e0ea-15ba-4346-b1c5-3b58c7190c70",
    name: "Health & Fitness - Gyms, Yoga, Pilates",
  },
  { uuid: "344e2834-a98d-4638-a09e-d6243ea21f60", name: "Health Foods" },
  {
    uuid: "3313c689-ca08-488b-9675-1ab0d6f03584",
    name: "Health Products - Nutritional & Dietary Supplements",
  },
  {
    uuid: "b2d95ae1-7ce5-4c70-a885-6db1bae73e4f",
    name: "Health Services / HMOs",
  },
  {
    uuid: "2cde8ba1-e261-4c03-bb1b-80818fedc4c2",
    name: "Hobbies & Recreational Goods",
    ausPost: true,
    categories: [
      {
        uuid: "1aefddb1-ef53-48bd-9cca-383dbb3c151f",
        name: "Sporting & Outdoor Goods",
        ausPost: true,
      },
      {
        uuid: "3a3ae9fb-40dc-4322-b3f7-f4748f495851",
        name: "Automotive Parts & Accessories",
        ausPost: true,
      },
      {
        uuid: "5a3d8e1e-11b7-4679-83f4-74aca7d01353",
        name: "Hobbies, Games & Toys",
        ausPost: true,
      },
    ],
  },
  {
    uuid: "bcc38de6-1cd9-429c-ad05-a993baf04d2c",
    name: "Holidays",
    royMorgan: true,
  },
  {
    uuid: "519ee3d2-3cb4-4bac-a184-7f18b6ce3275",
    name: "Home & Garden",
    ausPost: true,
    categories: [
      {
        uuid: "d4364960-9051-4a04-871b-d5e05d1701cf",
        name: "Consumer Electronics",
        ausPost: true,
      },
      {
        uuid: "282b458d-f829-4853-b146-24edeae23055",
        name: "Homewares and appliances",
        ausPost: true,
      },
      {
        uuid: "7ae36b90-83d2-4917-9fb7-80af1f406345",
        name: "Pet Products",
        ausPost: true,
      },
      {
        uuid: "e580695a-8a2b-4152-bf1b-b9a5d3777585",
        name: "Tools & Garden",
        ausPost: true,
      },
    ],
  },
  { uuid: "5930d3b0-b867-4ee4-a541-b78735d9fdde", name: "Hotels - Pubs" },
  { uuid: "fa32433a-0b13-4081-bb4a-24d077c2f6e7", name: "Internet providers" },
  { uuid: "a3cada34-428c-4763-bf0a-61e634447862", name: "Manufacturing" },
  {
    uuid: "74238aeb-1edf-4715-a400-e39d7984d81e",
    name: "Meat processing - Butchers, Butcher shops",
  },
  { uuid: "d448b440-82bf-4411-9531-98f0fd40a62e", name: "Nursing Homes" },
  {
    uuid: "fad74cc3-440d-4dbf-b9fc-ab27bcbb5b74",
    name: "Online Goods",
    ausPost: true,
  },
  { uuid: "ad053bbd-315d-4552-855e-60fb6207918a", name: "Personal Hygiene" },
  {
    uuid: "685a8412-3edb-4f03-87d9-bb8b0178fbc9",
    name: "Pets",
    royMorgan: true,
  },
  { uuid: "66411470-c16e-4263-9593-e47b7c33957d", name: "Pharmacy" },
  {
    uuid: "bcc24e52-fb37-4191-a8cb-a4e86d6aa760",
    name: "Philanthropists & Non-Profits",
  },
  { uuid: "615c17ae-2649-4b62-b6fb-1ec58b721e61", name: "Phone Companies" },
  {
    uuid: "a454a7f8-00d2-4144-92f5-bca32dcabfea",
    name: "Property/Home",
    royMorgan: true,
  },
  { uuid: "e78ca08c-9361-480d-8a00-e483d11491fa", name: "Racing" },
  { uuid: "78f8e746-e44f-4be2-8014-7d03ac0535f4", name: "Rail" },
  { uuid: "12ec7ee8-7f00-40b9-9de2-aab02e7f3e9e", name: "Restaurant" },
  {
    uuid: "ee0deae5-d40a-431c-af02-0f08ed63c96a",
    name: "Retail",
    royMorgan: true,
  },
  { uuid: "6e492a1a-496b-4fbd-bb14-96a690a674fb", name: "Retirement Living" },
  { uuid: "0a9710dc-1004-4dfe-9a99-93d917e3ee3f", name: "Security Services" },
  {
    uuid: "7b597a7d-0149-4cb1-99af-17997305bb74",
    name: "Social, community, home care and disability services",
  },
  {
    uuid: "861a7aa0-2b6b-44bf-94e0-753f23ebcbcd",
    name: "Smokers",
    royMorgan: true,
  },
  {
    uuid: "8a95e478-2109-4fc9-8a75-9669d0fe9b93",
    name: "Sporting Clubs and Organisations",
  },
  {
    uuid: "56ffff48-09a2-47ac-a2c7-3106e6476525",
    name: "Sport",
    royMorgan: true,
  },
  {
    uuid: "9bafbb07-aa44-4385-a84c-deb9b4a00ce5",
    name: "Technology Items",
    royMorgan: true,
  },
  { uuid: "24a0a0a9-3508-4c83-b4a9-429110f0edc4", name: "Tobacco" },
  {
    uuid: "32266138-fc06-4f90-b40b-f8ae220df71e",
    name: "Tradesman - Plumber, Electrician, Builder etc",
  },
  { uuid: "9da33763-9aa9-43ad-a2d4-9280fb5b6bc7", name: "Transportation" },
  {
    uuid: "8304a15a-3df4-4102-a4de-48b3a886e832",
    name: "Trash Collection/Waste Management",
  },
  {
    uuid: "2e4c4e1a-5c7e-4bd8-8776-3768d6924b3a",
    name: "Universities, Colleges & Schools",
  },
  {
    uuid: "19b0521f-b6a4-4839-b22c-7f0e36615324",
    name: "Utilities - AGL, etc etc",
  },
  {
    uuid: "c5991d80-47d7-421f-9ad4-054c5d2c1cd9",
    name: "Variety Stores",
    ausPost: true,
    categories: [
      {
        uuid: "36943012-de83-4966-932a-509344afe2b6",
        name: "Major & Discount Stores",
        ausPost: true,
      },
      {
        uuid: "81fb3c2e-d7f9-464b-8e11-e8080028086d",
        name: "Marketplaces",
        ausPost: true,
      },
    ],
  },
  { uuid: "d4f12a03-bafa-4dd0-a57d-ca02f520fe21", name: "Other" },
];
