import { lazy } from "react";
import Projects from "../pages/private/projects/Projects";
import ProjectSettings from "../pages/private/projectSettings/ProjectSettings";
import Overview from "../components/projectSettings/overview/Overview";
import Clients from "../pages/private/client/Clients";
import Research from "../pages/private/research/Research";
import Templates from "../pages/private/templates/Templates";
import AddToolsToProject from "../pages/private/tools/addToolToProject/AddToolsToProject";
import ToolBrand from "../pages/private/tools/brandStrategy/BrandStrategy";

const CoreCustomers = lazy(() =>
  import("../components/projectSettings/coreCustomer/CoreCustomers")
);
const PResearch = lazy(() =>
  import("../components/projectSettings/research/Research")
);
const Competitors = lazy(() =>
  import("../components/projectSettings/competitors/Competitors")
);
const UserAccess = lazy(() =>
  import("../components/projectSettings/userAccess/UserAccess")
);

const ProjectResearchDetails = lazy(() =>
  import("../components/research/ProjectResearchDetails/ProjectResearchDetails")
);

const Details = lazy(() => import("../pages/private/details/Details"));
const MyDetails = lazy(() =>
  import("../components/details/myDetails/MyDetails")
);
const WhiteLabel = lazy(() =>
  import("../components/details/whitelabel/WhiteLabel")
);
const BillingAndInvoice = lazy(() =>
  import("../components/details/billingAndInvoice/BillingAndInvoice")
);
const CompanyDetails = lazy(() =>
  import("../components/details/companyDetails/CompanyDetails")
);
const ClientArchive = lazy(() =>
  import("../components/details/clientArchive/ClientArchive")
);
const Integration = lazy(() =>
  import("../components/details/integration/Integration")
);
const UserManagement = lazy(() =>
  import("../components/details/userManagement/UserManagement")
);

const Admin = lazy(() => import("../pages/private/admin/Admin"));

const Reporting = lazy(() => import("../components/admin/reporting/Reporting"));
const ResearchDocuments = lazy(() =>
  import("../components/admin/researchDocuments/ResearchDocuments")
);
const AddResearch = lazy(() =>
  import("../components/admin/researchDocuments/addResearch/AddResearch")
);
const Transactions = lazy(() =>
  import("../components/admin/transactions/Transactions")
);
const Agencies = lazy(() => import("../components/admin/agencies/Agencies"));
const AddAgency = lazy(() =>
  import("../components/admin/agencies/addAgency/AddAgency")
);
const Users = lazy(() => import("../components/admin/users/Users"));
const AddUser = lazy(() => import("../components/admin/users/addUser/AddUser"));

const ToolBrandIntro = lazy(() =>
  import("../components/tools/brandStrategy/introduction/Introduction")
);
const ToolBrandDir = lazy(() =>
  import("../components/tools/brandStrategy/discovery/Discovery")
);
const ToolBrandRes = lazy(() =>
  import("../components/tools/brandStrategy/research/Research")
);
const ToolBrandSS = lazy(() =>
  import("../components/tools/brandStrategy/settingsSlides/SettingsSlides")
);
const ToolBrandWorkshop = lazy(() =>
  import("../components/tools/brandStrategy/workshop/Workshop")
);
const ToolBrandDownload = lazy(() =>
  import("../components/tools/brandStrategy/download/Download")
);
const CustomTemplateComponent = lazy(() =>
  import("../components/template/customTemplate/CustomTemplateComponent")
);

const OtherTemplate = lazy(() =>
  import("../components/template/Other/OtherTemplate")
);
const ProjectArchive = lazy(() =>
  import("../components/client/archive/ProjectArchive")
);
const ResView1 = lazy(() =>
  import("../components/research/researchView/view1/View1")
);
const ResView2 = lazy(() =>
  import("../components/research/researchView/view2/View2")
);
const WIP = lazy(() => import("../components/client/WIP/WipComponent"));
const ClientsSettings = lazy(() =>
  import("../components/client/settings/ClientSettings")
);
const OverviewSettings = lazy(() =>
  import("../components/client/settings/overview/Overview")
);
const ClientCoreCustomers = lazy(() =>
  import("../components/client/settings/coreCustomer/CoreCustomers")
);
const ClientSettingsDetails = lazy(() =>
  import("../components/client/settings/details/Details")
);
const ClientSettingsBranding = lazy(() =>
  import("../components/client/settings/branding/Branding")
);
const CSResearch = lazy(() =>
  import("../components/client/settings/research/Research")
);
const CSCompetitors = lazy(() =>
  import("../components/client/settings/competitors/Competitors")
);
const DYIComponent = lazy(() =>
  import("../components/research/researchView/diy/DIY")
);
const PRTool = lazy(() => import("../components/tools/PRTools/PRTools"));
const FeedBackPro = lazy(() =>
  import("../components/feedback-pro/ArtWorkPeople")
);
const Review = lazy(() => import("../components/feedback-pro/review/Review"));
const Approval = lazy(() =>
  import("../components/feedback-pro/approval/Approval")
);
const PublicApproval = lazy(() =>
  import("../components/feedback-pro/public/ApprovalComponent")
);
const PublicReview = lazy(() =>
  import("../components/feedback-pro/public/ReviewComponent")
);

export const routes = [
  {
    path: "/tools/pr-tools",
    element: PRTool,
    role: "private",
    name: "Public Relations",
  },
  {
    path: "/research/add-dyi",
    element: DYIComponent,
    role: "private",
  },
  {
    path: "/research/view-Diy/:id",
    element: DYIComponent,
    role: "private",
  },
  {
    path: "/research/view-research/:id",
    element: ProjectResearchDetails,
    role: "private",
  },
  {
    path: "/research/view-research/:id/download",
    element: ProjectResearchDetails,
    role: "private",
  },
  {
    path: "/clients/settings/:id",
    element: ClientsSettings,
    role: "private",
    name: "Client Settings",
    subRouting: true,
    subRoute: [
      {
        path: "",
        element: OverviewSettings,
      },
      {
        path: "details",
        element: ClientSettingsDetails,
      },
      {
        path: "branding",
        element: ClientSettingsBranding,
      },
      {
        path: "core-customers",
        element: ClientCoreCustomers,
      },
      {
        path: "research",
        element: CSResearch,
      },
      {
        path: "competitors",
        element: CSCompetitors,
      },
    ],
  },
  {
    path: "/clients/wip",
    element: WIP,
    role: "private",
    name: "Export WIP",
  },
  {
    path: "/research/:id",
    element: ResView1,
    role: "private",
  },
  {
    path: "/research/view-australiapost",
    element: ResView2,
    role: "private",
    name: "Australia Post",
  },
  {
    path: "/research/view-smrtr",
    element: ResView2,
    role: "private",
    name: "SMRTR",
  },
  {
    path: "/research/view-shopfully",
    element: ResView2,
    role: "private",
    name: "Shopfully",
  },
  {
    path: "/research/view-abs",
    element: ResView2,
    role: "private",
    name: "ABS",
  },
  {
    path: "/archive-project",
    element: ProjectArchive,
    role: "private",
    name: "Archived Projects",
  },
  {
    path: "/archive-project",
    element: ProjectArchive,
    role: "private",
    name: "Archived Projects",
  },
  {
    path: "/templates/:id",
    element: OtherTemplate,
    role: "private",
  },
  {
    path: "/templates/add-template/:id",
    element: OtherTemplate,
    role: "private",
  },
  {
    path: "/templates/custom-template",
    element: CustomTemplateComponent,
    role: "private",
    name: "Custom Template",
  },
  {
    path: "/templates/custom-template/:id",
    element: CustomTemplateComponent,
    role: "private",
    name: "Custom Template",
  },
  {
    notLazy: true,
    path: "/projects/settings",
    element: ProjectSettings,
    role: "private",
    name: "Project Settings",
    subRouting: true,
    subRoute: [
      {
        notLazy: true,
        path: "",
        element: Overview,
      },
      {
        path: "core-customers",
        element: CoreCustomers,
      },
      {
        path: "research",
        element: PResearch,
      },
      {
        path: "competitors",
        element: Competitors,
      },
      {
        path: "user-access",
        element: UserAccess,
      },
      {
        name: "Project Settings",
        path: "user-access/add-user/:id",
        element: MyDetails,
      },
    ],
  },
  {
    path: "/projects/artwork-people",
    element: FeedBackPro,
    role: "private",
    // name: "Project Settings",
    // subRouting: true,
    // subRoute: [
    //   {
    //     path: "",
    //     element: FeedBackPro,
    //   },
    // ],
  },
  {
    path: "/projects/review-feedback",
    element: Review,
    role: "private",
  },
  {
    path: "/projects/approval",
    element: Approval,
    role: "private",
  },
  {
    path: "/public/review-feedback",
    element: PublicReview,
    role: "all",
  },
  {
    path: "/public/approval",
    element: PublicApproval,
    role: "all",
  },
  {
    path: "/projects/tool/:name/:type/:id",
    element: ToolBrand,
    notLazy: true,
    role: "private",
    name: "Initial Discovery Workshop",
    subRouting: true,
    subRoute: [
      {
        path: "",
        element: ToolBrandIntro,
      },
      {
        path: "discovery",
        element: ToolBrandDir,
      },
      {
        path: "research",
        element: ToolBrandRes,
      },
      {
        path: "settings-slides",
        element: ToolBrandSS,
      },
      {
        path: "workshop",
        element: ToolBrandWorkshop,
      },

      {
        path: "download",
        element: ToolBrandDownload,
      },
    ],
  },
  {
    path: "/profile",
    element: Details,
    role: "private",
    name: "Details",
    subRouting: true,
    subRoute: [
      {
        name: "My Details",
        path: "",
        element: MyDetails,
      },
      {
        name: "Company Details",
        path: "company-details",
        element: CompanyDetails,
      },
      {
        name: "White Label",
        path: "white-label",
        element: WhiteLabel,
      },
      {
        name: "Billing & Invoice",
        path: "billing-invoice",
        element: BillingAndInvoice,
      },
      {
        name: "Client Archive",
        path: "client-archive",
        element: ClientArchive,
      },
      {
        name: "Integrations",
        path: "integrations",
        element: Integration,
      },
      {
        name: "User Management",
        path: "user-management",
        element: UserManagement,
      },
      {
        name: "User Management",
        path: "user-management/edit-user/:id",
        element: MyDetails,
      },
      {
        name: "User Management",
        path: "user-management/add-user",
        element: MyDetails,
      },
    ],
  },
  {
    path: "/templates",
    notLazy: true,
    element: Templates,
    role: "private",
    name: "Templates",
  },
  {
    path: "/research",
    notLazy: true,
    element: Research,
    role: "private",
    name: "Research Marketplace",
  },
  {
    path: "/projects/research",
    notLazy: true,
    element: Research,
    role: "private",
    name: "Add research to this project",
  },
  {
    path: "/projects",
    notLazy: true,
    element: Projects,
    role: "private",
    name: "Projects",
  },
  {
    path: "/projects/add-tool",
    notLazy: true,
    element: AddToolsToProject,
    role: "private",
    name: "Add tool to project",
  },
  {
    path: "/clients",
    notLazy: true,
    element: Clients,
    role: "private",
    name: "Clients",
  },
  {
    path: "/admin",
    element: Admin,
    role: "private",
    name: "Admin",
    subRouting: true,
    subRoute: [
      {
        name: "Reporting",
        path: "",
        element: Reporting,
      },
      {
        name: "Research Documents",
        path: "research-documents",
        element: ResearchDocuments,
      },
      {
        name: "Research Documents",
        path: "research-documents/add-research",
        element: AddResearch,
      },
      {
        name: "Transactions",
        path: "transactions",
        element: Transactions,
      },
      {
        name: "Agencies",
        path: "agencies",
        element: Agencies,
      },
      {
        name: "Agencies",
        path: "agencies/add-agency",
        element: AddAgency,
      },
      {
        name: "Users",
        path: "users",
        element: Users,
      },
      {
        name: "Users",
        path: "users/add-user",
        element: AddUser,
      },
    ],
  },
];
