import { Checkbox, FormControlLabel } from "@mui/material";

const CustomCheckBox = ({
  label,
  value,
  checked,
  onchange,
  color = "secondary",
  onBlur = () => {},
  disable = false,
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Checkbox
          color={color}
          checked={checked}
          onChange={(e) => onchange(e.target.checked)}
          onBlur={onBlur}
          disabled={disable}
        />
      }
      label={label}
      sx={{
        ".MuiFormControlLabel-label": {
          fontSize: "14px !important",
        },
      }}
    />
  );
};

export default CustomCheckBox;
