import { createTheme } from "@mui/material";
import { store } from "../../redux/store";
import WebFont from "webfontloader";
const loadWithFont = (font) => {
  if (font) {
    WebFont.load({
      google: {
        families: [`${font}:200,300,400,500,600,700,800`],
      },
    });
  }
};
export const theme = () => {
  const theme = store.getState().theme;

  loadWithFont(theme.googleFont);

  return createTheme({
    typography: {
      fontFamily: theme.googleFont || "",
      tab: {
        textTransform: "none !important",
        fontWeight: "600 !important",
        fontSize: "16px !important",
      },
      button: {
        textTransform: "none",
      },
    },
    palette: {
      primary: {
        main: theme.primaryColorHexCode || "#CAE2E6",
      },
      secondary: {
        main: theme.secondaryColorHexCode || "#1D2D91",
      },
    },
  });
};
